<template>
  <v-container>
    <v-row class="d-flex align-start">
      <v-col lg="9" class="my-5 pr-5">
        <h1 class="indigo--text text--lighten-1 text-center">
          UN AÑO CON EMMET FOX
        </h1>
        <div class="d-flex justify-center mb-5">
          <v-img src="../../public/emmet_fox.png" max-width="40%"></v-img>
        </div>
        <p class="mb-5 text-center">{{ efHoy.dia }} | {{ efHoy.titulo }}</p>

        <p class="text-justify" v-html="efHoy.cuerpo"></p>
      </v-col>
      <v-col lg="3" class="pl-2"><PanelDerecho /></v-col>
    </v-row>
  </v-container>
</template>
<script>

import PanelDerecho from "../components/Inicio/PanelDerecho";
export default {
  name: "UnAnoConEmmetFox",
  components: {
    PanelDerecho,
  },
  computed: {
    efHoy() {
      const emmetFoxHoy = this.$route.params.id;
      return this[emmetFoxHoy];
    },
  },
  data() {
    return {
      diciembre8: {
        dia: "8 DE DICIEMBRE",
        titulo: "CUATRO “NOES”",
        cuerpo: "<p>No te apresures, vas a vivir para siempre -en algún lugar-.  De hecho, estás en la eternidad ahora; por lo tanto, ¿por qué apurarte?</p><p>No te preocupes.  Perteneces a Dios y Dios es Amor; y por lo tanto, ¿por qué inquietarse?</p><p>No condenes, como no puedes meterte bajo la piel de otro individuo, no puedes saber qué dificultades ha tenido que enfrentar -cuanta tentación o malos entendidos, o estupidez-. No te has perfeccionado a ti mismo, y podrías ser mucho peor en sus zapatos.</p><p>No  te resientas. Si un daño ha sido hecho, la  Gran Ley se ocupará de eso.  Elévate en consciencia y libérate al tiempo que liberas al delincuente.  El perdón es la más fuerte medicina.</p><p><i>…Porque no hay autoridad sino de parte de Dios.</i> (Romanos 13:1).</p>"
      },
      diciembre9: {
        dia: "9 DE DICIEMBRE",
        titulo: "LAS PUERTAS PERDURABLES",
        cuerpo: "<p>Leer Salmo 24.</p><p>El Salmo vigésimo cuarto es el gran resumen de la enseñanza bíblica, sobre dejar que Dios entre a tu vida.</p><p>De Yo Soy es la tierra y su plenitud… La llave para el verdadero significado de esta primera estrofa, se encuentra en dos palabras vitales, “Señor” y “tierra”.  En la Biblia, la palabra “Señor” significa el YO SOY.  La “tierra” es un término general, cubriendo toda expresión o manifestación bajo la jurisdicción del YO SOY.  Ahora, todo problema realmente surge de la creencia que la tierra está sujeta al dominio de algún Poder externo o ley, que es capaz de gobernarla independientemente del  YO SOY. Pero la Ley del Ser es que el hombre es la imagen y  semejanza de Dios, y tiene completo dominio sobre todas sus condiciones, y este Salmo enfatiza este maravilloso hecho, añadiendo: “El mundo, y los que en el moran”.</p><p><i>Subirá al monte de Yo Soy.</i> (Salmo 24).</p><p>El monte de Yo Soy o Su lugar sagrado, significan la realización de Dios. Es esa vívida, real sensación de la Presencia. Cuando uno logra esto, tiene un maravilloso poder para ayudar a sanar a otros. Alcanzar este estado es el verdadero objetivo de todas nuestras plegarias.</p><p><i>El limpio de manos y puro de corazón…</i></p><p>Pero, ¿quiénes son los puros de corazón? Fundamentalmente, la pureza significa completa lealtad a la creencia en un Omniabarcante y Omnipotente Dios, nuestro Padre que está en los cielos. Aférrate a  Dios -eso es pureza-.</p><p>Mantener la mente conscientemente leal al Único Poder, es solo la mitad de la batalla. La otra mitad es purificar y reeducar el alma, no simplemente desde los groseros pecados que todos reconocen, sino de las mil y una concesiones a la creencia de limitación que llena la vida diaria de la humanidad. Esto es tener “manos limpias” y ser capaz de ascender ese maravilloso “monte de Yo Soy”.</p>"
      },
      diciembre10: {
        dia: "10 DE DICIEMBRE",
        titulo: "ENTRA EL REY DE GLORIA",
        cuerpo: "<p>Leer Salmo 24.</p><p><i>Él recibirá bendición de Jehová y justicia del Dios de salvación.</i></p><p>Tal es la generación de los que rebuscan, de los que buscan tu rostro…a muchos les puede parecer que la purificación del corazón es una tarea larga y tediosa, pero hay que recordar que cuando oramos es Dios quien trabaja y no nosotros.  Si usas el Poder de la Palabra, viejos hábitos de pensamiento se romperán y nuevos ocuparán su lugar; y esto es porque recibirás virtud o pensamiento correcto desde Dios.  Has visto Su Rostro y debes comenzar a expresar algo de Su Naturaleza, porque siempre nos convertimos en lo que contemplamos.</p><p><i>Alzad, oh puertas, vuestras cabezas, y alzad vosotras puertas eternas y entrará el Rey de Gloria.</i></p><p>Las “puertas” y “entradas” simbolizan  entendimiento, y es solo mediante el logro de un alto grado de entendimiento que el rey de la gloria -la vívida realización de Dios que estamos buscando- puede venir a  nuestras almas.  Se nos dice entonces que no preguntemos quién es el rey de la gloria y qué representa.  Es nada menos que el Señor, fuerte y poderoso en batalla, y la batalla que pelea, por supuesto, es nuestra batalla.</p><p><i>Jehová de los ejércitos  es el Rey de la Gloria.</i></p>"
      },
      diciembre11: {
        dia: "11 DE DICIEMBRE",
        titulo: "DALE TIEMPO",
        cuerpo: "<p>A veces las personas aceptan la idea de que un cambio de pensamiento, además de volverse a Dios en oración, transformará sus vidas en armonía y libertad. La lógica de este principio los mueve y se ponen a trabajar sobre esto en serio. Entonces, después de algunos días, dicen: “Nada ha sucedido, después de todo”, y se dejan caer de nuevo dentro de su vieja forma de pensar negativo.</p><p>Esto es extremadamente tonto. Los resultados de muchos años de pensamiento negativo son raramente corregidos en unos pocos días. Nadie que siga una nueva dieta física o régimen médico esperará cosechar los beneficios en tan corto tiempo. Debes conservar la nueva forma de pensamiento y rehusarte a desalentar por los aparentes fracasos del principio.</p><p>El motivo exacto para adoptar el pensamiento correcto es que es lo correcto, y que el pensamiento erróneo es erróneo; y debemos hacer lo correcto sea que esto parezca pagar dividendos o no. Por supuesto que paga dividendos -fabulosos dividendos-, pero usualmente requiere un poco de perseverancia a pesar de la lentitud del principio.</p><p><i>Y me buscaréis y me hallaréis, porque me buscaréis de todo vuestro corazón.</i> (Jeremías 29:13).</p>"
      },
      diciembre12: {
        dia: "12 DE DICIEMBRE",
        titulo: "EL PECADO IMPERDONABLE",
        cuerpo: "<p>La Biblia menciona la existencia de un pecado imperdonable, y esto ha atemorizado grandemente a innumerables cristianos.</p><p>Seamos absolutamente claros sobre un punto. No hay pecado que un ser humano pueda cometer que Dios no perdone, pero hay un pecado que no puede perdonar hasta que lo hagamos posible. Este pecado consiste en cerrarnos a la inspiración o guía de Dios. Si tu mente ya está resuelta acerca de toda cosa perteneciente a Dios, si determinas que ahora sabes toda la verdad y que no puedes estar equivocado; entonces no será posible que el Espíritu Santo abra tus ojos al error y te guíe hacia una verdad superior.  Naturalmente, en tanto permanezcas en ese estado mental, no podrás recibir ninguna ayuda o mejoría, y solo en ese sentido en que tu pecado es imperdonable -imperdonable mientras dure-. Cuando cambies de actitud, la iluminación vendrá y el pecado será destruido.</p><p><i>He aquí, yo estoy a la puerta y llamo; si alguno oye mi voz y abre la puerta, entraré a él, y cenaré con él, y él conmigo.</i> (Apocalipsis 3:20).</p>"
      },
      diciembre13: {
        dia: "13 DE DICIEMBRE",
        titulo: "LA PRÁCTICA HACE LA REALIDAD",
        cuerpo: "<p>La única parte de nuestra religión que es real es la que expresamos en nuestras vidas diarias. Ideales sobre los que no actuamos en la práctica no son más que meras teorías abstractas. En realidad, esos ideales pretendidos son un serio obstáculo, porque endrogan el alma.</p><p>Si quieres recibir algún beneficio de tu religión, tienes que practicarla, y el lugar para practicarla es justamente aquí, donde estás. Y el momento para hacerlo es ahora.</p><p>Sobre la oración un escritor dijo: “Mezcla amor en el pan que horneas; envuelve fuerza y valor en el paquete que le haces a la mujer con la cara cansada. Entrega confianza y candor con la moneda que pagas al hombre con los ojos suspicaces. “Esto resume la práctica de la Presencia de Dios”.</p><p><i>Al que te pide, dale…</i> (Mateo 5:42).</p>"
      },
      diciembre14: {
        dia: "14 DE DICIEMBRE",
        titulo: "¿ES ESTE TU SOMBRERO?",
        cuerpo: "<p>¿Por qué no ha sido respondida tu plegaria? Tal vez lo ha sido. Por más extraño que parezca, sucede a menudo que recibimos una respuesta a nuestra plegaria y no la reconocemos. Algunos de nosotros hemos tenido demostraciones en nuestra posesión por semanas o meses y no lo hemos sabido.  Este error es causado por la delineación. Hemos inconscientemente decidido que la demostración  debe tomar una forma particular, y como esa forma no ha aparecido, pensamos que hemos fallado. En realidad, probablemente hemos tenido una mejor demostración de la que esperábamos, pero por el momento estamos ciegos a ello.</p><p>Si un niño ha orado por un sombrero de hombre (porque piensa que se verá bien o lo hará mayor) no lo obtendrá puesto que la Divina Sabiduría sabe que no puede usarlo. Obtendrá un buen sombrero de la clase que le pueda ser útil.  Oramos a menudo por cosas para las cuales no estamos realmente preparados, pero si oramos científicamente, eso no importará, ya que la Inteligencia Creativa nos enviará la cosa que realmente necesitamos.</p><p><i>Busca a Dios por su Propia Causa, por la alegría de estar con Él, y las demostraciones cuidarán de sí mismas.</i></p><p><i>Yo me regocijaré en Yo Soy.</i> (Salmo 104:34).</p>"
      },
      diciembre15: {
        dia: "15 DE DICIEMBRE",
        titulo: "NO PUEDES TENER AMBOS",
        cuerpo: "<p>No puedes tener una torta, y al mismo tiempo comértela.</p><p>No puedes tener paz mental, y al mismo tiempo una dolencia.</p><p>No puedes tener un sentimiento de Divino Amor y al mismo tiempo estar nervioso.</p><p>No puedes tener un sentimiento de tolerancia, bondad y fe, y al mismo tiempo tener problemas digestivos.</p><p>No puedes tener armonía desarrollándose continuamente en tu vida, y al mismo tiempo disfrutar la murmuración y la crítica.</p><p>No puedes tener armonía desarrollándose continuamente en tu vida, y al mismo tiempo disfrutar la murmuración y la crítica.</p><p>No puedes construir una nueva consciencia y un nuevo cuerpo, y al mismo tiempo vivir mentalmente en el pasado muerto.</p><p><i>…Escogéis hoy a quien sirváis…pero yo y mi casa serviremos a Yo Soy.</i> (Josué 24:15).</p>"
      },
      diciembre16: {
        dia: "16 DE DICIEMBRE",
        titulo: "HAZLO TRABAJAR",
        cuerpo: "<p><i>Compra la verdad, y no la vendas, las sabiduría, la enseñanza y la inteligencia.</i> (Proverbios 23:23).</p><p>Todo lo que tienes de la Verdad es lo que entiendes de ella -y lo que entiendes siempre lo demuestras-. De esto se deriva naturalmente que la única forma para mejorarte tú y tus condiciones es incrementar el entendimiento. Y el único camino para incrementar el entendimiento es hacer uso práctico de la Verdad que ya conoces. El conocimiento que de hecho no usas, es solo conocimiento intelectual y es estéril; e incluso este desaparecerá con el tiempo a través de la falta de uso.</p><p>Tú nunca demostrarás o progresarás sobre meras teorías que no has puesto a prueba. Tú nunca demostrarás o crecerás espiritualmente sobre lo que está en un libro de texto o una lectura hasta que hayas empezado a poner tal conocimiento dentro de la práctica. Es en alto grado mejor tener un dedal lleno de conocimiento espiritual y usarlo, que tener una montaña entera de correcta doctrina espiritual, la mayoría de la cual nunca has puesto a trabajar.</p>"
      },
    };
  },
};
</script>
